<template>
    <header class="page-title">
        <h1><i class="bi bi-people-fill"></i> 稼働集計</h1>
    </header>

    <section class="section">
        <form class="row mb-3" @submit.prevent="fetch()">
            <div class="col-6">
                <div class="input-group">
                    <form-input-month v-model="month_from" required></form-input-month>
                    <span class="input-group-text">〜</span>
                    <form-input-month v-model="month_to" required></form-input-month>
                </div>
            </div>
            <div class="col-3">
                <form-select v-model="department" :options="departments" option_value="department_id" option_label="department_name" empty_option="全部門" :disabled="departments.length === 0"></form-select>
            </div>
            <div class="col-4">
                <button type="submit" class="btn btn-primary">表示</button>
            </div>
            <div class="col-11 text-end">
                <div v-if="$store.getters['auth/canExportCsv']()" class="dropdown">
                    <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-download"></i> CSV
                    </button>
                    <ul class="dropdown-menu">
                        <li><button class="dropdown-item" type="button" @click="exportSummaryCsv()">集計CSVエクスポート</button></li>
                        <li><button class="dropdown-item" type="button" @click="exportDetailCsv()">明細CSVエクスポート</button></li>
                        <li><router-link :to="{name: 'Export'}" class="dropdown-item">エクスポート履歴</router-link></li>
                    </ul>
                </div>
                <button v-else type="button" class="btn btn-outline-primary" disabled><i class="bi bi-download"></i> CSV</button>
            </div>
        </form>
        <table class="table table-bordered">
            <thead class="table-dark text-center">
                <tr v-if="user_loading">
                    <th></th>
                    <th v-for="i in Array(user_count)" :key="i"><text-loader :width="6"></text-loader></th>
                    <th>合計</th>
                </tr>
                <!--ヘッダ行-->
                <tr v-else>
                    <th></th>
                    <th v-for="user of users" :key="user.user_id">{{ user.user_name }}</th>
                    <th>合計</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <template v-if="user_loading || project_loading">
                    <tr v-for="i in Array(project_count)" :key="i">
                        <td><text-loader :width="12"></text-loader></td>
                        <td v-for="i in Array(user_count + 1)" :key="i"><text-loader :width="3"></text-loader></td>
                    </tr>
                </template>
                <template v-else>
                    <!--明細行-->
                    <tr v-for="project of this.projects" :key="project.project_id">
                        <td>{{ project.project_name }}</td>
                        <td v-for="user of users" :key="user.user_id">
                            <template v-if="work_loading">
                                <text-loader :width="6"></text-loader>
                            </template>
                            <template v-else-if="user.user_id in work_map && project.project_id in work_map[user.user_id]">
                                <router-link class="btn btn-link p-0" :to="{name: 'WorkDetail', query: {month_from, month_to, project: project.project_id, user: user.user_id}}">
                                    {{ $helper.clock(work_map[user.user_id][project.project_id]) }}
                                </router-link>
                            </template>
                            <template v-else>
                                -
                            </template>
                        </td>
                        <!--行合計-->
                        <td>
                            <template v-if="work_loading">
                                <text-loader :width="6"></text-loader>
                            </template>
                            <template v-else-if="project.project_id in project_total_map">
                                <router-link class="btn btn-link p-0" :to="{name: 'WorkDetail', query: {month_from, month_to, project: project.project_id}}">
                                    {{ $helper.clock(project_total_map[project.project_id]) }}
                                </router-link>
                            </template>
                            <template v-else>
                                -
                            </template>
                        </td>
                    </tr>
                </template>
            </tbody>
            <tfoot class="table-light text-center">
                <tr v-if="user_loading">
                    <th>合計</th>
                    <th v-for="i in Array(user_count)" :key="i"><text-loader :width="6"></text-loader></th>
                    <th><text-loader :width="6"></text-loader></th>
                </tr>
                <!--合計行-->
                <tr v-else>
                    <th>合計</th>
                    <td v-for="user of users" :key="user.user_id">
                        <template v-if="work_loading">
                            <text-loader :width="6"></text-loader>
                        </template>
                        <template v-else-if="user.user_id in user_total_map">
                            <router-link class="btn btn-link p-0" :to="{name: 'WorkDetail', query: {month_from, month_to, user: user.user_id}}">
                                {{ $helper.clock(user_total_map[user.user_id]) }}
                            </router-link>
                        </template>
                        <template v-else>
                            -
                        </template>
                    </td>
                    <!--総合計-->
                    <td>
                        <router-link class="btn btn-link p-0" :to="{name: 'WorkDetail', query: {month_from, month_to}}">
                            {{ $helper.clock(work_total) }}
                        </router-link>
                    </td>
                </tr>
            </tfoot>
        </table>
    </section>
</template>

<script>
import FormInputMonth from '@/components/forms/FormInputMonth';
import FormSelect from '@/components/forms/FormSelect';
import TextLoader from '@/components/tools/TextLoader';
import User from "@/models/entities/user";
import Department from "@/models/entities/department";
import Project from "@/models/entities/project";

export default {
    name: 'Work',
    components: {
        FormInputMonth,
        FormSelect,
        TextLoader,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            user_loading: true,
            project_loading: true,
            work_loading: true,
            users: [],
            projects: [],
            work_map: {},
            user_total_map: {},
            project_total_map: {},
            work_total: 0,
            user_count: 6,
            project_count: 10,
            departments: [],
        }
    },
    mounted() {
        this.fetch();

        this.$http.get('/list/department')
        .then((response) => {
            this.departments = response.data.map((row) => {return new Department(row)});
        })
    },
    methods: {
        fetch() {
            this.user_loading = true;
            this.users = [];
            this.$http.get('/list/user', {params: {month_from: this.month, month_to: this.month, department: this.department}})
            .then((response) => {
                this.users = response.data.map((row) => {return new User(row)});
                this.user_count = this.users.length;
                this.user_loading = false;
            });

            this.project_loading = true;
            this.projects = [];
            this.$http.get('/list/project', {params: {month_from: this.month_from, month_to: this.month_to}})
            .then((response) => {
                this.projects = response.data.map((row) => {return new Project(row)});
                this.project_count = this.projects.length;
                this.project_loading = false;
            });

            this.work_loading = true;
            this.work_map = {};
            this.user_total_map = {};
            this.project_total_map = {};
            this.work_total = 0;

            this.$http.get('/work/summary', {params: {month_from: this.month_from, month_to: this.month_to, department: this.department}})
            .then((response) => {
                for (let row of response.data) {
                    let user_id = row.user.user_id;
                    let project_id = row.project.project_id;
                    if (!(user_id in this.work_map)) {
                        this.work_map[user_id] = {};
                    }
                    this.work_map[user_id][project_id] = row.work_time;

                    if (!(user_id in this.user_total_map)) {
                        this.user_total_map[user_id] = 0;
                    }
                    this.user_total_map[user_id] += row.work_time;

                    if (!(project_id in this.project_total_map)) {
                        this.project_total_map[project_id] = 0;
                    }
                    this.project_total_map[project_id] += row.work_time;

                    this.work_total += row.work_time;
                }

                // 稼働時間降順に案件をソート
                this.projects.sort((a, b) => {
                    if (!(a.project_id in this.project_total_map)) {
                        return 1;
                    }
                    if (!(b.project_id in this.project_total_map)) {
                        return -1;
                    }
                    return this.project_total_map[a.project_id] > this.project_total_map[b.project_id] ? -1 : 1;
                })

                this.work_loading = false;
            });
        },
        exportSummaryCsv() {
            this.startScreenLoading();

            this.$http.post('/work/summary/export', {
                month_from: this.month_from,
                month_to: this.month_to,
                department_id: this.department
            })
            .then(() => {
                this.$router.push({name: 'Export'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        exportDetailCsv() {
            this.startScreenLoading();

            this.$http.post('/work/export', {
                month_from: this.month_from,
                month_to: this.month_to,
                department_id: this.department
            })
            .then(() => {
                this.$router.push({name: 'Export'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    },
    computed: {
        month_from: {
            get() {
                return this.$store.state.condition.month_from;
            },
            set(value) {
                this.$store.commit('condition/setMonthFrom', value);
            },
        },
        month_to: {
            get() {
                return this.$store.state.condition.month_to;
            },
            set(value) {
                this.$store.commit('condition/setMonthTo', value);
            },
        },
        department: {
            get() {
                return this.$store.state.condition.department;
            },
            set(value) {
                this.$store.commit('condition/setDepartment', value);
            },
        },
    },
}
</script>

<style scoped>
</style>
